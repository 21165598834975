import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { Subject } from 'rxjs'
import { UIHelpers } from 'src/app/helpers/ui-helpers'
import { LoaderOptionsObject } from 'src/app/interfaces/loaderOptions'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { Pagination, Resp } from 'src/app/interfaces/response'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { ConstantsService } from 'src/app/services/constants.service'
import { DataService } from './data.service'

@Component({
  selector: 'app-parallels',
  templateUrl: './parallels.component.html',
  styleUrls: ['./parallels.component.css']
})
export class ParallelsComponent implements OnInit {
  dataStatus = 'fetching'
  parallelList: Array<any> = []
  selectedIndex: number
  modalRef: BsModalRef
  pagination: Pagination<any>
  searchKeyword = ''
  selectedStatus = ''
  searchKeyword$: Subject<string> = new Subject<string>()
  searchKeywordSub: any
  modalTitle = ''
  loginLoading = false
  dataForm: FormGroup
  formAdd:FormGroup
  selectedId: any
  filters = {
    keyword: '',
    status: '',
    page: 1,
    perPage: 10
  }
  breadCrum = [
    {
      link: '/admin/parallels',
      value: 'Parallels'
    }
  ]
  loaderOptions: LoaderOptionsObject = ConstantsService.createLoaderConfig(5, 7, 1)


  waiting: {
    search: boolean
    save: boolean
    userStatus: Array<any>
  }

  constructor(
    private ds: DataService,
    private fb: FormBuilder,
    public ui: UIHelpers,
    private alert: IAlertService,
    public router: Router,
    private route: ActivatedRoute,
    private ms: BsModalService
  ) {
    this.waiting = {
      search: false,
      save: false,
      userStatus: []
    }

    this.formAdd = this.fb.group({
      id: new FormControl(null),
      //name: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      firstName: new FormControl(null, [Validators.required, Validators.maxLength(200)]),
      lastName: new FormControl(null, [Validators.maxLength(200)]),
      // house: new FormControl(null, [Validators.maxLength(100)]),
      // street: new FormControl(null, [Validators.maxLength(100)]),
      city: new FormControl(null, [Validators.maxLength(100)]),
      // state: new FormControl(null, [Validators.maxLength(100)]),
      zipCode: new FormControl(null, [Validators.maxLength(100)]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      contact: new FormControl(null, [
          Validators.required,
          Validators.maxLength(15)
      ]),
     
      address: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      addressTwo: new FormControl(null)
    })

    this.dataForm = this.fb.group({
      id: new FormControl(null),
      amount: new FormControl(null, [Validators.required]),
      description: new FormControl('', [Validators.required]),
    })

    this.route.queryParams.subscribe((params) => {
      if (params.page) {
        this.filters.page = params.page
      }
      if (params.perPage) {
        this.filters.perPage = params.perPage
      }
      if (params.keyword) {
        this.filters.keyword = params.keyword
      }
      if (params) {
        this.search()
      }
    })
  }

  ngOnInit(): void {
    this.searchKeywordSub = this.searchKeyword$
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((searchKeyword) => {
        this.filters.page = 1
        this.search()
      })
  }
  setPagination(page: any) {
    this.filters.page = page
    this.router.navigate(['/admin/parallels'], {
      queryParams: this.filters,
      replaceUrl: true
    })
  }

  searchKeywordChange(value: string) {
    this.searchKeyword$.next(value)
  }

  search(): void {
    this.waiting.search = true
    this.filters.keyword = this.searchKeyword
    this.ds.getList(this.filters).subscribe((resp: any) => {
      this.waiting.search = false
      if (resp.success === true) {
        this.parallelList = resp.data.data || []
        this.pagination = resp.data as Pagination<any>
        this.dataStatus = 'done'
      }
    })
  }

  openModal(formModal: any, id: number, index: number) {
    this.selectedIndex = index
    if (index > -1) {
      this.modalTitle = 'Add Transaction'
      this.selectedId = id
      this.selectedIndex = index
    }
    this.modalRef = this.ms.show(
      formModal,
      {
        class: 'modal-md modal-dialog-centered back-office-panel',
        backdrop: 'static',
        ignoreBackdropClick: true,
        keyboard: false
      }
    )
  }

  openRegModal(formModal: any, id: number, index: number) {
    this.modalTitle = 'Add New Parallel'
    this.selectedIndex = index
    if (index > -1) {
        this.modalTitle = 'Edit Parallel'
        this.selectedId = id
        this.selectedIndex = index

        console.log('data---',this.parallelList[index])
        
        let u = this.parallelList[index]
        // this.formAdd.patchValue({
        //     id:u.id,
        //     name:u.name,
        //     email:u.user.email,
        //     address:u.address,
        //     contact:u.contactOne,
        // })

        this.formAdd.patchValue(u)
        this.formAdd.patchValue({email:u.user.email, contact:u.contactOne})
    }
    this.modalRef = this.ms.show(
        formModal,
        {
            class: 'modal-lg modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        }
    )
}

formatPhoneNumber(phoneNumber: string): string {
  const cleanNum = phoneNumber.replace(/\D/g, '');
  const match = cleanNum.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumber;
}

  save(f: any) {
    this.waiting.save = true
    if (this.dataForm.value.amount <= 0) {
      this.waiting.save = false
      this.alert.error('amount should be greater than 0')
      this.dataForm.controls.amount.setValue(0)
      return
    }
    if (this.dataForm.invalid) {
      this.waiting.save = false
      this.alert.error('Please fill-in valid data in all fields & try again.')
      return
    }
    const params = {
      userId: this.selectedId,
      amount: this.dataForm.value.amount,
      description: this.dataForm.value.description
    }
    this.ds.addTransaction(params).subscribe((resp: any) => {
      this.waiting.save = false
      if (resp.success === true) {
        this.alert.success('Transaction Added Successfully')
        this.modalRef.hide()
      } else {
        this.alert.error(resp?.errors.general || '')
      }
      this.cancelRegionButton(f)
    })

  }

  get g() {
    return this.dataForm.controls
  }

  get t() {
    return this.formAdd.controls
}

  cancelButton(f: any) {
    f.resetForm()
    this.modalRef.hide()
    this.selectedIndex = -1
  }

  statusConfirmingModal(changeStatus: TemplateRef<any>, id: number, ind: number, status: string) {
    this.selectedId = id
    this.selectedIndex = ind
    this.selectedStatus = status
    this.modalRef = this.ms.show(changeStatus, {
      class: 'modal-md modal-dialog-centered back-office-panel',
      backdrop: 'static',
      ignoreBackdropClick: true,
      keyboard: false
    })
  }

  changeStatusActive() {
    this.waiting.userStatus[this.selectedIndex] = true
    const params = {
      id: this.selectedId,
      //status: this.selectedStatus
    }
    this.ds.activate(params).subscribe((resp: any) => {
      this.waiting.userStatus[this.selectedIndex] = false
      if (resp.success === true) {
        this.alert.success('Status Changes Active Successfully')
        this.modalRef.hide()
        this.parallelList[this.selectedIndex].user.status = this.selectedStatus
        this.search()
      } else {
        this.alert.error(resp?.message || '')
      }
    })
  }

  changeStatusInactive() {
    this.waiting.userStatus[this.selectedIndex] = true
    const params = {
      id: this.selectedId,
      //status: this.selectedStatus
    }
    this.ds.deactivate(params).subscribe((resp: any) => {
      this.waiting.userStatus[this.selectedIndex] = false
      if (resp.success === true) {
        this.alert.success('Status Changes Inactive Successfully')
        this.parallelList[this.selectedIndex].user.status = this.selectedStatus
        this.search()
        this.modalRef.hide()
      } else {
        this.alert.error(resp?.message || '')
      }
    })
  }

  delete() {
    this.waiting.save = true
    const param = {
      id: this.selectedId
    }
    this.ds.delete(param).subscribe((resp: Resp<any>) => {
      this.waiting.save = false
      if (resp.success === false) {
        this.alert.error(resp?.message as string)
        this.modalRef.hide()
        return
      }
      this.parallelList.splice(this.selectedIndex, 1)
      this.modalRef.hide()
      this.alert.success('Parallel Deleted Successfully!')
    })
  }

  confirmDelModal(template: TemplateRef<any>, id: number, index: any) {
    this.selectedId = id
    this.selectedIndex = index
    this.modalRef = this.ms.show(template, {
      class: 'modal-sm modal-dialog-centered back-office-panel'
    })
  }

  saveUser(f: any) {
    this.loginLoading = true
    if (this.formAdd.invalid) {
        this.alert.error('Please fill in complete data & then try again')
        this.loginLoading = false
        return
    }
    
    let params = this.formAdd.value

    if(this.dataForm.value.id > -1 && this.dataForm.value.id != null){
        params.id = this.formAdd.value.id
        this.ds.update(params).subscribe((resp: any) => {
            this.loginLoading = false
            if (resp.success == false) {
                this.alert.error(resp.errors.general)
                this.loginLoading = false
                return
            } else {
                this.parallelList[this.selectedIndex]= resp.data
                this.alert.success('updated successfully!!')
            }
            f.resetForm()
            this.modalRef.hide()
            
        })
    }else{
        this.ds.add(params).subscribe((resp: any) => {
            this.loginLoading = false
            if (resp.success == false) {
                this.alert.error(resp.errors.general)
                return
            } else {
                this.alert.success('add successfully!!')
            }
            f.resetForm()
            this.modalRef.hide()
            this.parallelList.push(resp.data)
        })
    }
}

  cancelRegionButton(f: any) {
    this.modalRef.hide()
    f.resetForm()
  }

  ngOnDestroy(): void {
    this.searchKeywordSub.unsubscribe()
  }
}

