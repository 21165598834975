<app-breadcrums [data]="breadCrum"></app-breadcrums>
<div class="row">
    <div class="d-flex justify-content-between">
        <div class="col-md-3 cg-input-group">
            <label>Parallel</label>
            <ng-select [(ngModel)]="parallelId" (ngModelChange)="updateFilter()" class="autocomplete">
                <ng-option *ngFor="let parallel of searchParallelList" [value]="parallel.id">{{ parallel.name }}</ng-option>
            </ng-select>
        </div>
        <div class="col-md-3 cg-input-group ms-2">
            <label>Start Date</label>
            <input type="text" class="cg" [(ngModel)]="filters.startDate" bsDatepicker [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }" placeholder="Start Date" />
        </div>
        <div class="col-md-3 cg-input-group ms-2">
            <label>End Date</label>
            <input type="text" class="cg" [(ngModel)]="filters.endDate" bsDatepicker [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }" placeholder="End Date" />
        </div>
        <div class="col cg-input-group d-flex justify-content-between align-items-end ms-2">
            <div class="btn-group" dropdown>
                <button [disabled]="waiting.search" [ngClass]="waiting.search ? 'in-progress' : ''" type="button" class="cg secondary" (click)="filters.page = 1; searcha()">
                    Search
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>

            <div class="blnc" style="display: none">
                <label>Opening Balance</label>
                <h3 class="text-right primary" *ngIf="openingBalance >= 0">
                    <strong>${{ openingBalance | number: '1.2-2' }}</strong>
                </h3>
                <h3 class="text-right primary" *ngIf="openingBalance < 0">
                    <strong>-${{ openingBalance * -1 | number: '1.2-2' }}</strong>
                </h3>
            </div>

            <div class="blnc">
                <label>Current Balance</label>
                <h3 class="text-right primary" *ngIf="currentBalance >= 0">
                    <strong>${{ currentBalance | number: '1.2-2' }}</strong>
                </h3>
                <h3 class="text-right primary" *ngIf="currentBalance < 0">
                    <strong>-${{ currentBalance * -1 | number: '1.2-2' }}</strong>
                </h3>
            </div>
            <!-- <button type="button" class="cg primary" (click)="openModal(addForm, -1, -1)">Add New</button> -->
        </div>
    </div>
</div>
<div class="box">
    <div class="table-responsive">
        <table class="cg primary">
            <thead class="sticky">
                <tr>
                    <th>Date</th>
                    <th style="width: 50%">Description</th>
                    <th class="text-center">Debit</th>
                    <th class="text-center">Credit</th>
                    <th class="text-center">Balance</th>
                </tr>
            </thead>

            <tbody *ngIf="dataStatus === 'fetching' && this.parallelId > 1" [appSkeletonTabelLoader]="loaderOptions"></tbody>

            <tbody *ngIf="transactionList.length > 0">
                <tr *ngFor="let d of transactionList; let i = index">
                    <td>{{ moment.utc(d.created_at).local().format('MM/DD/YYYY') }}</td>

                    <td>{{ d.description }}</td>

                    <td class="text-right">
                        <ng-container *ngIf="d.amount < 0"> -${{ d.amount * -1 | number: '1.2-2' }} </ng-container>
                    </td>

                    <td class="text-right">
                        <ng-container *ngIf="d.amount > 0"> ${{ d.amount | number: '1.2-2' }} </ng-container>
                    </td>

                    <td class="text-right">
                        <ng-container *ngIf="d.parallel.balance > 0"> ${{ d.parallel.balance | number: '1.2-2' }}</ng-container>
                        <ng-container *ngIf="d.parallel.balance < 0"> -${{ d.parallel.balance * -1 | number: '1.2-2' }}</ng-container>
                    </td>
                </tr>
            </tbody>

            <tbody *ngIf="dataStatus === 'done' && transactionList.length === 0">
                <tr>
                    <td colspan="5">
                        <app-no-data-found></app-no-data-found>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<app-ipagination [pagination]="pagination" [activePage]="filters.page" (pageChangeEvent)="setPagination($event)" *ngIf="dataStatus === 'done'"></app-ipagination>
