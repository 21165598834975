import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { Component, Input, OnChanges, TemplateRef, ViewChild } from '@angular/core'
import { DataService } from '../data.service'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { UIHelpers } from 'src/app/helpers/ui-helpers'
import { Pagination } from 'src/app/interfaces/response'
import { ApiService } from 'src/app/services/api.service'
import { ActivatedRoute, Router } from '@angular/router'
import { ConstantsService } from 'src/app/services/constants.service'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import moment from 'moment'

@Component({
    selector: 'app-transactions',
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent {
    dataStatus = 'fetching'
    transactionList: Array<any> = []
    searchParallelList: Array<any> = []
    descriptionList: Array<any> = []
    totalBalance: Array<number> = []
    pagination: Pagination<any>
    parallelId: any = -1
    moment = moment
    loginLoading = false
    openingBalance = 0
    currentBalance = 0
    pageOpeningBalance = 0
    description = ''
    amountTypeCredit = 0
    amountTypeDebit = 0
    id: number
    transactionForm: FormGroup
    selectedIndex: number
    modalRef: BsModalRef
    modalTitle = ''

    filters: any = {
        page: 1,
        per_page: 10,
        startDate: moment().subtract(3, 'months').toDate(),
        endDate: new Date(),
        userId: ''
    }
    waiting: {
        save: boolean
        search: boolean
    }
    breadCrum = [
        {
            link: '/admin/parallels',
            value: 'Parallels',
            params: {}
        }
    ]

    loaderOptions = {
        rows: 5,
        cols: 5,
        colSpans: {
            0: 1
        }
    }

    constructor(
        public ds: DataService,
        public fb: FormBuilder,
        public ui: UIHelpers,
        private alert: IAlertService,
        public api: ApiService,
        public router: Router,
        private route: ActivatedRoute,
        public cs: ConstantsService,
        private ms: BsModalService
    ) {
        this.parallelId = this.route.snapshot.queryParamMap.get('parallelId')
        this.filters.userId = this.parallelId
        this.breadCrum.push({
            link: '/admin/parallels/transactions',
            params: { parallelId: this.parallelId },
            value: 'Transactions'
        })

        this.waiting = {
            search: false,
            save: false
        }
        this.route.queryParams.subscribe((params) => {
            if (params.page) {
                this.filters.page = params.page
            }
            if (params.startDate) {
                this.filters.startDate = new Date(params.startDate)
            }
            if (params.endDate) {
                this.filters.endDate = new Date(params.endDate)
            }
            if (params.parallelId) {
                this.filters.userId = +params.parallelId
                this.parallelId = +params.parallelId
            }
            if (params) {
                this.search()
                this.getParallels()
            }
        })

        this.transactionForm = this.fb.group({
            id: new FormControl(null),
            amount: new FormControl(null, [Validators.required]),
            description: new FormControl(null, [Validators.required, Validators.maxLength(500)])
        })
    }

    ngOnInt() {
        this.updateFilter()
        this.search()
    }

    // ngOnChanges() {
    //     this.getParallels()
    // }
    get g() {
        return this.transactionForm.controls
    }

    getParallels() {
        this.ds.getParallelList().subscribe((resp: any) => {
            this.searchParallelList = resp.data || []
        })
    }

    updateFilter() {
        this.filters.userId = this.parallelId
        console.log('dropdownID', this.filters.userId)
    }

    search(): void {
        this.waiting.search = true
        const paramsToSend: any = { ...this.filters }
        paramsToSend.startDate = moment(paramsToSend.startDate).format('YYYY-MM-DD')
        paramsToSend.endDate = moment(paramsToSend.endDate).format('YYYY-MM-DD')

        this.ds.getTransactions(paramsToSend).subscribe((resp: any) => {
            this.waiting.search = false
            if (resp.success === true) {
                this.transactionList = resp.data?.data?.data
                this.pagination = resp.data?.data as Pagination
                this.dataStatus = 'done'
                this.openingBalance = resp.data.openingBalance
                this.currentBalance = resp.data.currentBalance
                this.pageOpeningBalance = resp.data.pageOpeningBalance
                this.filters.startDate = new Date(this.filters.startDate)
                this.filters.endDate = new Date(this.filters.endDate)
            }
        })
    }

    searcha() {
        if (!this.parallelId || this.parallelId === -1) {
            this.alert.error('Please Select Parallel')
            return
        }
        const paramsToSend: any = { ...this.filters }
        paramsToSend.startDate = moment(paramsToSend.startDate).format('YYYY-MM-DD HH:mm:ss')
        paramsToSend.endDate = moment(paramsToSend.endDate).format('YYYY-MM-DD HH:mm:ss')
        this.router.navigate(['/admin/parallels/transactions'], {
            queryParams: paramsToSend,
            replaceUrl: true
        })
    }

    setPagination(page: number) {
        this.filters.page = page
        const paramsToSend: any = { ...this.filters }
        paramsToSend.startDate = moment(paramsToSend.startDate).format('YYYY-MM-DD HH:mm:ss')
        paramsToSend.endDate = moment(paramsToSend.endDate).format('YYYY-MM-DD HH:mm:ss')
        this.router.navigate(['/admin/parallels/transactions'], {
            queryParams: paramsToSend,
            replaceUrl: true
        })
    }

    openModal(modal: TemplateRef<any>, index: number) {
        this.selectedIndex = index
        if (index > -1) {
            this.transactionForm.controls.id.setValue(this.transactionList[index].id)
            this.id = this.transactionList[index].id
            this.description = this.transactionList[index].description
            if (Number(this.transactionList[index].amount) < 0) {
                this.amountTypeDebit = Number(+this.transactionList[index].amount) * -1
            } else {
                this.amountTypeCredit = Number(+this.transactionList[index].amount)
            }
            this.modalTitle = 'Edit Transaction'
        }

        this.modalRef = this.ms.show(modal, {
            class: 'modal-md modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
    }

    cancel() {
        this.amountTypeCredit = 0
        this.amountTypeDebit = 0
        this.description = ''
        this.modalRef.hide()
    }
}
